import './App.css';
import { Navbar, Body, Team, Footer, Faq, Contact } from '../src/components/';

function App() {
  return (
    <div>
      <Navbar />
      <Body />
      <Team />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
