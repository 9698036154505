import React from 'react'
import Instructor from '../assets/svg/doodle.svg'
import emailjs from 'emailjs-com'

const Contact = () => {

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE, process.env.REACT_APP_EMAIL_TEMPLATE, e.target, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };

    return (
        <div className="grid max-w-screen-xl grid-cols-1 gap-8 px-8 py-16 mx-auto rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 dark:bg-gray-800 dark:text-gray-100" id="contact">
            <div className="flex flex-col justify-between">
                <div className="space-y-2">
                    <h2 className="text-4xl font-bold leading-tight lg:text-5xl">Let's talk!</h2>
                    <div className="dark:text-gray-400">Are you ready to <span className="text-emerald-500">Gro</span>?</div>
                </div>
                <img src={Instructor} alt="Instructor image" className="p-6 h-52 md:h-64" />
            </div>
            <form novalidate="" className="space-y-6 ng-untouched ng-pristine ng-valid" onSubmit={sendEmail}>
                <div>
                    <label for="name" className="text-sm">Full name</label>
                    <input id="name" type="text" placeholder="Full Name" name='name' className="w-full p-3 rounded bg-gray-200" />
                </div>
                <div>
                    <label for="email" className="text-sm">Email</label>
                    <input placeholder='email' id="email" name='email' type="email" className="w-full p-3 rounded bg-gray-200" />
                </div>
                <div>
                    <label for="message" className="text-sm">Message</label>
                    <textarea id="message" rows="3" placeholder='Type Here' className="w-full p-3 rounded bg-gray-200" name='message'></textarea>
                </div>
                <button type="submit" className="w-full p-3 text-sm font-semibold border rounded dark:border-gray-100 hover:text-white hover:bg-emerald-500">Send Message</button>
            </form>
        </div>
    )
}

export default Contact
