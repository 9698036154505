import React from 'react'

const Faq = () => {
  return (
    <div>
      <section className="dark:bg-gray-800 dark:text-gray-100">
		<div className="container flex flex-col justify-center p-4 mx-auto md:p-8" id="FAQ">
			<p className="p-2 text-sm font-medium tracking-wider text-center uppercase">How it works</p>
			<h2 className="mb-12 text-4xl font-bold leading-none text-center sm:text-5xl">Frequently Asked <span className="text-emerald-500">Questions</span></h2>
			<div className="grid gap-10 md:gap-8 sm:p-3 md:grid-cols-2 lg:px-12 xl:px-32">
				<div>
					<h3 className="font-semibold">How do I get involved?</h3>
					<p className="mt-1 dark:text-gray-400">If you're a student, feel free to check our list of supported universities <a className="text-blue-400 hover:text-emerald-500 hover:underline hover:cursor-pointer">here</a>!<br />
					If you are an administration member at your university and are interested in partnering with Gro, visit <a href='#contact' className="text-blue-400 hover:text-emerald-500 hover:underline hover:cursor-pointer">here</a> to contact us!
					</p>
				</div>
				<div>
					<h3 className="font-semibold">Where do your resources come from?</h3>
					<p className="mt-1 dark:text-gray-400">All of our resources that are available in the Student Dashboard is provided by official government and medical sources, as well as by our Advisors! You can rest assured that you are getting the most up-to-date and official information available to you.</p>
				</div>
				<div>
					<h3 className="font-semibold">What is the price for universities?</h3>
					<p className="mt-1 dark:text-gray-400">Please <a href='#contact' className="text-blue-400 hover:text-emerald-500 hover:underline hover:cursor-pointer">contact</a> us to get our full pricing model! This is for university administrators only, and we will verify your credentials before discussing pricing for universities. We will not disclose pricing to students or outside members here.</p>
				</div>
				<div>
					<h3 className="font-semibold">Where can I get additional information?</h3>
					<p className="mt-1 dark:text-gray-400">In the future, we will provide more informational sections to this website! For now, please fill out the <a href='#contact' className="text-blue-400 hover:text-emerald-500 hover:underline hover:cursor-pointer">contact</a> for any additional information you may need, or contact us directly at: </p>
				</div>
				<div>
					<h3 className="font-semibold">How are you different?</h3>
					<p className="mt-1 dark:text-gray-400">We differ from our competitors by putting an emphasis on preventative measures for our clients and their students. We want each student to be given the skills necessary to manage and improve their mental health in a very visual way. We offer a number of different tools and tracking mechanisms to help our students.</p>
				</div>
				{/* <div>
					<h3 className="font-semibold">Lorem ipsum dolor sit amet.</h3>
					<p className="mt-1 dark:text-gray-400">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione, fugit? Aspernatur, ullam enim, odit eaque quia rerum ipsum voluptatem consequatur ratione, doloremque debitis? Fuga labore omnis minima, quisquam delectus culpa!</p>
				</div> */}
			</div>
		</div>
	</section>
    </div>
  )
}

export default Faq
