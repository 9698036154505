import React from 'react'
import business from '../assets/png/Business_SVG.png'
import mDash1 from '../assets/png/dashboardMobile1.png'
import mDash2 from '../assets/png/dashboardMobile2.png'

import {RiPagesLine} from 'react-icons/ri';
import {MdOutlineSchool} from 'react-icons/md';
import {TfiWrite} from 'react-icons/tfi';
import {BsCheck2Circle} from 'react-icons/bs';

const Body = () => {
    return (
        <div>
            <section className="dark:bg-gray-800 dark:text-gray-100">
                <div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
                    <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
                        <h1 className="text-5xl font-bold leading-none sm:text-6xl">Improve Your Mental Health With
                            <span className='text-emerald-500'> Gro</span>
                        </h1>
                        <p className="mt-6 mb-8 text-lg sm:mb-12">Built for College Students,
                            <br className="hidden md:inline lg:hidden" /> by College Students.
                        </p>
                        <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
                            <a rel="noopener noreferrer" href="#team" className="px-8 py-3 text-lg font-semibold rounded dark:bg-emerald-500 dark:text-gray-900 hover:underline decoration-emerald-500">About Us</a>
                            <a rel="noopener noreferrer" href="#" className="px-8 py-3 text-lg font-semibold border rounded dark:border-gray-100 hover:text-white hover:bg-emerald-500">Get Started</a>
                        </div>
                    </div>
                    <div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
                        <img src={business} alt="Office Photo" className="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128 dark:bg-gray-500" />
                    </div>
                </div>
            </section>
            <section className="dark:bg-gray-800 dark:text-gray-100">
                <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl dark:text-gray-50">Take Charge of your <span className="text-emerald-500">Mental</span> Health</h2>
                        <p className="max-w-3xl mx-auto mt-4 text-xl text-center dark:text-gray-400">We give you the tools and resources needed to balance your school, work, personal life, and take control of your mental health.</p>
                    </div>
                    <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center" id='resources'>
                        <div>
                            <h3 className="text-2xl font-bold tracking-tight sm:text-3xl dark:text-gray-50"><span className="text-emerald-500">Resources</span> at a glance</h3>
                            <p className="mt-3 text-lg dark:text-gray-400">At Gro, we are giving you access to the tools and resources taught in Cognitive Behavioral Therapy, making it more accessible to college students.</p>
                            <div className="mt-12 space-y-12">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leading-6 dark:text-gray-50">Daily Wellness Checks</h4>
                                        <p className="mt-2 dark:text-gray-400">Fill out a short, daily survey that provides data that helps identify patterns and places to improve! View your mentality score visually through the smiley face a the top of your dashboard.</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leading-6 dark:text-gray-50">Track Your Assignments</h4>
                                        <p className="mt-2 dark:text-gray-400">We all know that time management is a huge stressor. Practice time management with our Kanban and Calendar functionality. Using the resources at your disposal, take back your time!</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leading-6 dark:text-gray-50">Manage More Than Just Your Classwork</h4>
                                        <p className="mt-2 dark:text-gray-400">Set time aside for your <i className="text-emerald-500">me</i> time. Plan your gym time, game time, reading time, and more! Our goal page, calendar, and Kanban let you rock your life, down to the minute.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-hidden="true" className="mt-10 lg:mt-0">
                            <img src={mDash1} alt="Mobile Dashboard One" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
                        </div>
                    </div>
                    <div>
                        <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center" id='b2b'>
                            <div className="lg:col-start-2">
                                <h3 className="text-2xl font-bold tracking-tight sm:text-3xl dark:text-gray-50">B<span className="text-emerald-500">2</span>B</h3>
                                <p className="mt-3 text-lg dark:text-gray-400">We are a business-to-business company, meaning that we go directly to the university to provide the best resources for your students at a reasonable price. View our list of supported universities <a href='' className="text-blue-400 hover:text-emerald-500 hover:underline">here</a>.</p>
                                <div className="mt-12 space-y-12">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg font-medium leading-6 dark:text-gray-50">Customizable Dashboard</h4>
                                            <p className="mt-2 dark:text-gray-400">Add a personalized touch for your students! We love school pride and think it's a great feature to add for your students to ensure they know where they call home.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg font-medium leading-6 dark:text-gray-50">Growth For Your Students</h4>
                                            <p className="mt-2 dark:text-gray-400">Give your students access to the tools taught in Cognitive Behavioral Therapy. We believe that being proactive is the correct approach, rather than reactive. Using our tools, your students will be able to improve their mental health, improve their productivity, and see results in their successes.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-7 h-7">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg font-medium leading-6 dark:text-gray-50">Affordable Pricing</h4>
                                            <p className="mt-2 dark:text-gray-400">Contact our team to hear about our pricing model and work out a plan that is best for you and your students! Contact us <a href='' className="text-blue-400 hover:text-emerald-500 hover:underline">here</a>!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
                                <img src={mDash2} alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dark:bg-gray-800 dark:text-gray-100">
                <div className="container flex flex-col p-6 mx-auto" id='HIW'>
                    <h2 className="py-4 text-3xl font-bold text-center">How it <span className="text-emerald-500">Works</span></h2>
                    <div className="divide-y divide-gray-700">
                        <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
                            <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                                <RiPagesLine size={100} />
                            </div>
                            <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                                <span className="text-xs tracking-wider uppercase dark:text-violet-400">Step 1 - <span className="text-emerald-500">Survey</span></span>
                                <span className="text-xl font-bold md:text-2xl">Wellness Check</span>
                                <span className="mt-4 dark:text-gray-300">Take a short daily survey that helps you track your mental state, recognize patterns, and visualize your results. Our cutting edge technology tracks these results and can notify your counselor or a specialist when you need a helping hand.</span>
                            </div>
                        </div>
                        <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
                            <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                                <MdOutlineSchool size={100} />
                            </div>
                            <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                                <span className="text-xs tracking-wider uppercase dark:text-violet-400">Step 2 - <span className="text-emerald-500">Study</span></span>
                                <span className="text-xl font-bold md:text-2xl">Tracking Schoolwork</span>
                                <span className="mt-4 dark:text-gray-300">Our software easily integrates with Canvas, allowing you to quickly and easily keep tabs on upcoming homework assignments, quizzes, tests, and more.</span>
                            </div>
                        </div>
                        <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
                            <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                            <TfiWrite size={90} />
                            </div>
                            <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                                <span className="text-xs tracking-wider uppercase dark:text-violet-400">Step 3 - <span className="text-emerald-500">Journal</span></span>
                                <span className="text-xl font-bold md:text-2xl">Write Your Thoughts</span>
                                <span className="mt-4 dark:bg-gray-800 dark:text-gray-300">Use our Journal section to record your thoughts on whatever topic you'd like! We supply you with a new prompt each day for you to reflect on if you are unsure what to write!</span>
                            </div>
                        </div>
                        <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
                            <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                            <BsCheck2Circle size={100} />
                            </div>
                            <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                                <span className="text-xs tracking-wider uppercase dark:text-violet-400">Step 4 - <span className="text-emerald-500">Aperiam</span></span>
                                <span className="text-xl font-bold md:text-2xl">Results</span>
                                <span className="mt-4 dark:text-gray-300">Our data centered approach will help you recognize the negative patterns in your life. You will be able to easily visualize and filter results from your wellness survey, see food intake, and use what you see to make the necessary adjustments in your life.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Body
