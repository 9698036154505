import React from 'react'
import { TbLeaf } from 'react-icons/tb'

const Navbar = () => {
    return (
        <div className="space-y-8 md:space-y-16 dark:bg-gray-800 dark:text-gray-100">
            <header className="p-4 dark:bg-gray-800 dark:text-gray-100">
                <div className="container flex justify-between h-16 mx-auto md:justify-center md:space-x-8">
                    <ul className="items-stretch hidden space-x-3 md:flex">
                        <li className="flex">
                            <a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent hover:text-gray-400 hover:border-emerald-500">Home</a>
                        </li>
                        <li className="flex">
                            <a rel="noopener noreferrer" href="#resources" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent hover:text-gray-400 hover:border-emerald-500">Resources</a>
                        </li>
                        <li className="flex">
                            <a rel="noopener noreferrer" href="#b2b" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent hover:text-gray-400 hover:border-emerald-500">B2B</a>
                        </li>
                    </ul>
                    <a rel="noopener noreferrer" href="#" aria-label="Back to homepage" className="flex items-center p-2">
                        <TbLeaf size="1x" color="#00A651" />
                    </a>
                    <ul className="items-stretch hidden space-x-3 md:flex">
                        <li className="flex">
                            <a rel="noopener noreferrer" href="#HIW" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent hover:text-gray-400 hover:border-emerald-500">HIW</a>
                        </li>
                        <li className="flex">
                            <a rel="noopener noreferrer" href="#FAQ" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent hover:text-gray-400 hover:border-emerald-500">FAQ</a>
                        </li>
                        <li className="flex">
                            <a rel="noopener noreferrer" href="#contact" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent hover:text-gray-400 hover:border-emerald-500">Contact</a>
                        </li>
                    </ul>
                    <button className="p-4 md:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 dark:text-gray-100">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                </div>
            </header>

        </div>
    )
}

export default Navbar
